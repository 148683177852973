"use strict";

/////////////
// Imports //
/////////////

import PubSub from "pubsub-js";
import { createCustomEvent, messages as MESSAGES } from "@wearegood/good-utilities";
import ShowHide from "Modules/ShowHide/ShowHide";
import * as CONSTANTS from "Modules/ShowHide/constants";

///////////////
// Constants //
///////////////

/////////////////////////
// Classes & Functions //
/////////////////////////

/**
 *
 *
 * @export
 * @class ActionsMenu
 * @extends {ShowHide}
 */
export default class SectionToggle extends ShowHide {
  constructor(element) {
    super(element);
    this.action = this.component.querySelectorAll(CONSTANTS.SEL_SECTION_TOGGLE)[0];
    this.content = this.component.querySelectorAll(CONSTANTS.SEL_SECTION_CONTENT)[0]; 
    this.current = false;

    // Call initial methods
    this.bindCustomMessageEvents();
    this.subscribeToMessages();
    this.setStartState();
  }

  /**
   * bindCustomMessageEvents - Description
   *
   * @returns {type} Description
   */
  bindCustomMessageEvents() {
    this.component.addEventListener(
      CONSTANTS.EVENT_SECTION_TOGGLE,
      this.toggleComponent.bind(this)
    );

    this.component.addEventListener(
      CONSTANTS.EVENT_SECTION_OPEN,
      this.handleOpenEvent.bind(this)
    )

    this.component.addEventListener(
      CONSTANTS.EVENT_SECTION_CLOSE,
      this.handleCloseEvent.bind(this)
    )
  }

  handleOpenEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    PubSub.publishSync(CONSTANTS.MESSAGE_CLOSE_SECTIONS, this.component);
    PubSub.publishSync(CONSTANTS.MESSAGE_CLOSE_SUBSECTIONS, this.component);
    
    this.openComponent();
  }

  handleCloseMessage(message, data) {
    let currID = this.component.getAttribute('id');
    let targetID = data.getAttribute('id');

    if(currID !== targetID) {
      this.component.dispatchEvent(createCustomEvent(CONSTANTS.EVENT_SECTION_CLOSE));
    }
  }

 /**
   *
   *
   * @memberof ShowHide
   */
  subscribeToMessages() {
    PubSub.subscribe(CONSTANTS.MESSAGE_CLOSE_SECTIONS, this.handleCloseMessage.bind(this));
  }
}
