
export const productData = {
  products: [
    {
      name:"T5 Thermostat",
      productNumber: "RCHT8612WF2005/U"
    },
    {
      name:"T9 Smart Thermostat",
      productNumber: "RCHT9610WFSW2003/U"
    },
    {
      name:"T6 Thermostat"
    }
  ]
}