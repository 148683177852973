"use strict";

////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";
import { createNodeFromHTML } from "@wearegood/good-utilities";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_MSA_COMP = "[data-msa=component]";

const SEL_MSA_TITLE = ".cp_MSA__sourceArticleTitle";

const SEL_MSA_SECTION = ".cp_MSA__sourceSection";
const SEL_MSA_SECTION_HEADING = ".cp_MSA__sourceSectionHeading";

const SEL_MSA_SUBSECTION = ".cp_MSA__sourceSubsection";
const SEL_MSA_SUBSECTION_HEADING = ".cp_MSA__sourceSubsectionHeading";
const SEL_MSA_SUBSECTION_CONTENT = ".cp_MSA__sourceSubsectionContent";

const ICON = `<span class="cp_MSA__toggleIcon">
<svg xmlns="http://www.w3.org/2000/svg" width="7.364" height="11.348" viewBox="0 0 7.364 11.348"><path d="M5.674,7.364,0,1.69,1.69,0,5.674,3.984,9.658,0l1.69,1.69Z" transform="translate(0 11.348) rotate(-90)" fill="#ffffff"/></svg>
</span>`;

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

// 
class MultiSectionArticle {
  constructor(elem) {
    this.component = elem;
    this.contentSections = this.component.querySelectorAll(SEL_MSA_SECTION);

    window.console.log('found one!');
    

    this.buildSmallScreenComp();

    this.buildLargeScreenComp();
  }

  buildSmallScreenComp () {

    let componentFrame = `
    <div class="cp_MSA__accordian">
      <div class="cp_MSA__progress">
        <div class="cp_MSA__progressBar">
          <span class="cp_MSA__progressBarPipe"></span>
        </div>
        <div class="cp_MSA__progressBarValue">40%</div>
      </div>
    </div>`;

    let componentNode = createNodeFromHTML(componentFrame).item(0);

    let componentSections = "";

    this.contentSections.forEach(section => {
      let sectionHeading = section.querySelector(SEL_MSA_SECTION_HEADING).innerHTML;
      let sectionID = section.getAttribute('id');

      let sectionTemplate = `
      <section id="${sectionID}" class="cp_MSA__section" data-showhide="component" data-showhide-config='{"animate":true,"speed":1000,"open":false,"type":"section"}'>
        <h2>
          <a href="#" class="cp_MSA__sectionControl--main cp_MSA__sectionControl" data-showhide-section="toggle">${sectionHeading}
            ${ICON}</a>
        </h2>
        <div class="cp_MSA__sectionContent" data-showhide-section="content">
        </div>
      </section>
      `;

      let sectionNode = createNodeFromHTML(sectionTemplate).item(0);

      let subSections = section.querySelectorAll(SEL_MSA_SUBSECTION);

      subSections.forEach(subsection => {
        let subsectionHeading = subsection.querySelector(SEL_MSA_SUBSECTION_HEADING).innerHTML;
        let subsectionContent = subsection.querySelector(SEL_MSA_SUBSECTION_CONTENT).innerHTML;
        let subsectionID = subsection.getAttribute('id');

        let subsectionTemplate = `
        <section id="${subsectionID}" class="cp_MSA__subsection" data-showhide="component" data-showhide-config='{"animate":true,"speed":1000,"open":false,"type":"subsection"}'>
				  <a href="#" class="cp_MSA__sectionControl--subsection cp_MSA__sectionControl" data-showhide-subsection="toggle">${ subsectionHeading }
					${ICON}</a>
				  <div class="cp_MSA__subsectionContent" data-showhide-subsection="content">
            ${ subsectionContent }
          </div>
        </section>
        `;

        let subsectionNode = createNodeFromHTML(subsectionTemplate).item(0);

        sectionNode.querySelector('.cp_MSA__sectionContent').appendChild(subsectionNode);
      });

      componentNode.appendChild(sectionNode);
    });

    this.component.querySelector('.cp_MSA__inner').appendChild(componentNode);
  }


  buildLargeScreenComp () {

    let articleTitle = this.component.querySelector(SEL_MSA_TITLE).innerHTML;

    let componentFrame = `
    <div class="cp_MSA__tabs" data-tabs="component" data-tabs-type="multi" data-tabs-scroll-type="auto">
      <div class="cp_MSA__progress">
        <div class="cp_MSA__progressBar">
          <span class="cp_MSA__progressBarPipe"></span>
        </div>
        <div class="cp_MSA__progressBarValue">40%</div>
      </div>

      <ul class="cp_MSA__tabsControlContainer">
        <li class="cp_MSA__articleTitle">${articleTitle}</li>
      </ul>

      <div class="cp_MSA__tabsContentContainer">

      </div>
    </div>`;

    let componentNode = createNodeFromHTML(componentFrame).item(0);


    this.contentSections.forEach(section => {
      let sectionHeading = section.querySelector(SEL_MSA_SECTION_HEADING).innerHTML;
      let sectionID = section.getAttribute('id');

      let sectionTabsTemplate = `
        <li class="cp_MSA__sectionTabs--main cp_MSA__sectionTabs" data-tabs="tab">
          <a href="#" class="cp_MSA__sectionControl--main cp_MSA__sectionControl" data-tabs-link-section="1">${sectionHeading}
            ${ICON}</a>
          <ul class="cp_MSA__sectionTabs--subsection cp_MSA__sectionTabs">

          </ul>
        </li>
      `;

      let sectionTabsNode = createNodeFromHTML(sectionTabsTemplate).item(0);

      let subSections = section.querySelectorAll(SEL_MSA_SUBSECTION);

      subSections.forEach(subsection => {
        let subsectionHeading = subsection.querySelector(SEL_MSA_SUBSECTION_HEADING).innerHTML;
        let subsectionContent = subsection.querySelector(SEL_MSA_SUBSECTION_CONTENT).innerHTML;
        let subsectionID = subsection.getAttribute('id');

        let subsectionTabTemplate = `
        <li class="cp_MSA__tabControl" data-tabs-tab="${subsectionID}">
          <a href="#" class="cp_MSA__sectionControl--subsection cp_MSA__sectionControl" data-tabs-link="${subsectionID}">${subsectionHeading}
          ${ICON}</a> 
        </li>
        `;

        let subsectionTabNode = createNodeFromHTML(subsectionTabTemplate).item(0);
        let subsectionTabsContainer = sectionTabsNode.querySelector('.cp_MSA__sectionTabs');

        subsectionTabsContainer.appendChild(subsectionTabNode);

        let subsectionContentTemplate = `
          <div class="cp_MSA__tabsectionContent" data-tabs-panel="${subsectionID}">
            <header class="cp_MSA__sectionHeader">${subsectionHeading}</header>
            <section class="cp_MSA__sectionBody">
              ${subsectionContent}
            </section>
          </div>
        `;

        let subsectionContentNode = createNodeFromHTML(subsectionContentTemplate).item(0);

        componentNode.querySelector('.cp_MSA__tabsContentContainer').appendChild(subsectionContentNode); 
      });

      componentNode.querySelector('.cp_MSA__tabsControlContainer').appendChild(sectionTabsNode);

      //componentNode.appendChild(sectionNode);
    });

    this.component.querySelector('.cp_MSA__inner').appendChild(componentNode);
  }
}


function initMSA () {
  const MSA_COMPS = document.querySelectorAll(SEL_MSA_COMP);

  MSA_COMPS.forEach((element) => {
    const newMultiSectionArticle = new MultiSectionArticle(element);
  });
}

export default {
  initMSA: initMSA
}
