"use strict";

////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";
import smoothscroll from 'smoothscroll-polyfill';
import { productData } from "Modules/ProductSearch/Data"; 
import { createNodeFromHTML } from "@wearegood/good-utilities";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_PRODUCT_SEARCH_COMP = "[data-product-search=component]";
const SEL_PRODUCT_SEARCH_FORM = "[data-product-search=form]";
const SEL_PRODUCT_SEARCH_FIELD = "[data-product-search=field]";
const SEL_PRODUCT_SEARCH_RESULTS = "[data-product-search=results]";

const DELAY_SEARCH_TIME = 2000;
const DELAY_RESULTS_SHOW = 500; 

const getProductCardTemplate = (data) => {
  // Provide fallbacks if the data we are using to populate the template is not found
  if(!data.name) {
    data.name = "Name not found";
  }

  if(!data.productNumber) {
    data.productNumber = "Product Number not found";
  }

  // Return the HTML with data populated
  return `
    <div class="cp_ProductCard">
      <figure class="cp_ProductCard__image"><img class="ob_Image" src="/assets/img/placeholder_image_square.svg" alt="placeholder" /></figure>
      <h2 class="cp_ProductCard__name">${data.name}</h2>
      <h3 class="cp_ProductCard__productNumber">${data.productNumber}</h3>
    </div>
  `;
};

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

smoothscroll.polyfill();

function getElementOffsetOnPage(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}


class ProductSearch {
  constructor(elem) {
    this.component = elem;
    this.searchForm = this.component.querySelector(SEL_PRODUCT_SEARCH_FORM);
    this.searchField = this.component.querySelector(SEL_PRODUCT_SEARCH_FIELD);
    this.searchResults = this.component.querySelector(SEL_PRODUCT_SEARCH_RESULTS);
    this.productsData = productData.products;

    //  Set up event listeners
    this.searchField.oninput = () => {
      this.manageSearchFieldInput();
    };

    this.searchForm.addEventListener('submit', this.manageFormSubmit.bind(this));
  }

  manageFormSubmit(event) {
    event.preventDefault();
    
    let offset = getElementOffsetOnPage(this.component);

    window.console.log(offset.top);

    window.scrollTo({
      top:offset.top,
      left:0,
      behavior:"smooth" 
    });

    this.updateProductList(this.searchField.value); 
  }

  manageSearchFieldInput() {
    if(this.searchField.value.length > 0) {
      this.searchField.classList.add('has_Content');
    } else {
      this.searchField.classList.remove('has_Content');
    }
  } 

  updateProductList(searchTerm) {
    if(searchTerm) {
      let tidiedTerm = searchTerm.trim();

      if(tidiedTerm.length > 0) {
        this.component.classList.add('is_Searching');

        setTimeout(() =>
        {
          this.productsData.forEach(product => {
            let productCard = generateProductCard(product).item(0);
            
            this.searchResults.appendChild(productCard);
          });

          setTimeout(() => {
            this.searchResults.classList.add('has_Results');
            this.component.classList.remove('is_Searching');
          },DELAY_RESULTS_SHOW);

        }, DELAY_SEARCH_TIME);
      }
    }
  }
}

/**
 *
 *
 * @param {*} productData
 */
function generateProductCard(productData) {
  return createNodeFromHTML(getProductCardTemplate(productData));
} 

/**
 *
 *
 */
function initialiseProductSearch () {

  const PRODUCT_SEARCH_COMPONENTS = document.querySelectorAll(SEL_PRODUCT_SEARCH_COMP);

  Array.prototype.forEach.call(PRODUCT_SEARCH_COMPONENTS, element => {
    const newComponent = new ProductSearch(element);
  });
}

/**
 *
 *
 */
function delegateEvents() {

}

/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
function initModule() {
  // Create delegated event listeners for the components within this module
  delegateEvents();
  initialiseProductSearch();
}

// API
export default {
  initModule: initModule
}

