"use strict";

////////////////////
// Module Imports //
//////////////////// 

import PubSub from "pubsub-js";
import smoothscroll from 'smoothscroll-polyfill';
import "nodelist-foreach-polyfill";
import "smoothscroll-polyfill";
import { createDelegatedEventListener, getOuterHeight, createCustomEvent, getIndexOfNode, messages as MESSAGES, createNodeFromHTML } from "@wearegood/good-utilities";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_HEADER = ".cp_PageHeader";

const SEL_PS_TAB_COMPONENT = "[data-ps-tabs=component]";
const SEL_PS_TAB_PANEL = "[data-ps-tabs=panel]";
const SEL_PS_TAB_CONTROLS = "[data-ps-tabs=tabs]";
const SEL_PS_TAB_TAB = "[data-ps-tabs=tab]";
const SEL_PS_TAB_CONTROL = "[data-ps-tabs=link]"; 
const SEL_PS_TAB_CONTROL_GLOBAL = "[data-ps-tabs=component] [data-ps-tabs=link]";
const SEL_PS_TAB_CONTROL_SECTION = "[data-ps-tabs-link-section]";
const SEL_PS_TAB_CONTROL_SECTION_GLOBAL = "[data-ps-tabs=component] [data-ps-tabs-link-section]";
const SEL_PS_TAB_HIDDEN_TOGGLE = "[data-ps-tabs-hidden=toggle]";

const CLASS_CURRENT = "is_Current";
const CLASS_STICKY = "is_Sticky";

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

class PageSectionTabs {
  /**
   *Creates an instance of TabbedContent.
   * @param {*} element
   * @memberof TabbedContent
   */
  constructor(element) {
    this.component = element;
    this.config = JSON.parse(this.component.dataset.tabsConfig) || {};
    this.autoplayDelay = this.config.autoplay || null;
    this.tabControlsContainer = this.component.querySelector(SEL_PS_TAB_CONTROLS);
    this.tabControls = null;
    this.tabControlTemplate = this.component.dataset.tabsTemplate || null;
    this.tabControlTextColour = this.component.dataset.tabsTextColour || "#fff";
    this.tabControlBackgroundColour = this.component.dataset.tabsBackgroundColour || "#000";
    this.tabPanels = this.component.querySelectorAll(SEL_PS_TAB_PANEL);
    this.currentIndex = 0;
    this.currentTab;
    this.pageHeader = document.querySelector(SEL_HEADER);

    this.autoplayer;

    this.bindCustomMessageEvents();
    this.subscribeToEvents();
    this.setupTabs();
  }

  /**
   * Set initial display of tabs.
   *
   * @memberof TabbedContent
   */
  setupTabs() {

    // Get the tab controls once they've been created
    this.tabPanels.item(this.currentIndex).classList.add(CLASS_CURRENT);
    this.tabControls = this.component.querySelectorAll(SEL_PS_TAB_CONTROL);
    if(this.tabControls.length > 0){
      this.tabControls.item(this.currentIndex).classList.add(CLASS_CURRENT);
    }

    if(this.autoplayDelay) {
      this.startAutoplay(this.autoplayDelay);
    }
  }

  /**
   * Update the current tab based on user interaction
   *
   * @param {*} e
   * @memberof TabbedContent
   */
  updateCurrentTab(index) {

    let targetIndex = index;

    // If the target index is not equal to the current index (i.e. the clicked tab isn't the current one )
    // update the tabbed content and scroll back to its start.
    if (targetIndex !== this.currentIndex) {

      if(this.tabPanels.item(this.currentIndex)) {
        this.tabPanels.item(this.currentIndex).classList.remove(CLASS_CURRENT);
        this.tabControls.item(this.currentIndex).classList.remove(CLASS_CURRENT);
      }

      if (this.tabPanels.item(targetIndex)) {
        this.tabPanels.item(targetIndex).classList.add(CLASS_CURRENT);
        this.tabControls.item(targetIndex).classList.add(CLASS_CURRENT);
      }

      this.currentIndex = targetIndex;
    }
  }

  advanceTabs(direction) {
    // Set next slide based on direction
    if (direction === 'n'){
      if((this.currentIndex+1) < this.tabControls.length){
        // let nextTab = this.tabControls.item(this.currentIndex).closest(SEL_PS_TAB_TAB).nextElementSibling.querySelector(SEL_PS_TAB_CONTROL);
        // nextTab.click();
        this.updateCurrentTab(this.currentIndex+1);
      } else {
        this.updateCurrentTab(0);
      }

    } else if (direction === 'p') {
      if(this.currentIndex > 0){
        // let prevTab = this.tabControls.item(this.currentIndex).closest(SEL_PS_TAB_TAB).prevElementSibling.querySelector(SEL_PS_TAB_CONTROL);
        // prevTab.click();
        this.updateCurrentTab(this.currentIndex-1);
      } else {
        this.updateCurrentTab(this.tabControls.length-1);
      }
    } else {
      window.console.log('no direction');
    }
  }

  /**
   *
   *
   * @memberof TabbedContent
   */
  handleAutoplayCycle() {
    this.advanceTabs('n');
  }

  /**
   *
   *
   * @param {*} delay
   * @memberof TabbedContent
   */
  startAutoplay(delay) {
    this.autoplayer = window.setInterval(this.handleAutoplayCycle.bind(this), this.autoplayDelay);
  }

  /**
   *
   *
   * @memberof TabbedContent
   */
  stopAutoplay() {
    window.clearInterval(this.autoplayer);
    this.autoplayer = null;
  }

  /**
   *
   *
   * @memberof TabbedContent
   */
  updateLayout() {
    
  }

  /**
   *
   *
   * @param {*} e
   * @memberof TabbedContent
   */
  handleTabSelectionEvent(e) {
    e.preventDefault();

    if(this.autoplayer) {
      this.stopAutoplay();
    }

    let targetIndex;

    // Get "tab" node if the click target is not already this
    if(("psTabs" in e.target.dataset) && e.target.dataset.psTabs === "tab") {
      targetIndex = getIndexOfNode(e.target);
    } else {
      let tabNode = e.target.closest(SEL_PS_TAB_TAB);
      targetIndex = getIndexOfNode(tabNode);
    }

    this.updateCurrentTab(targetIndex);
  }

  /**
   * Subscribes the component to global messages and sets the component's responses via internal custom events
   *
   * @memberof TabbedContent
   */
  subscribeToEvents() {
    PubSub.subscribe(MESSAGES.resize, () => {
      this.component.dispatchEvent(createCustomEvent("updateLayout"));
    });
  }

  /**
   * Bind custom messages for this class
   *
   * @memberof TabbedContent
   */
  bindCustomMessageEvents() {
    this.component.addEventListener("selectPageSelectTab", this.handleTabSelectionEvent.bind(this));
    //this.component.addEventListener("updateLayout", this.updateLayout.bind(this));
  }
}

/**
 * delegateEvents - Create delegated event listeners for the components within this module
 *
 * @returns {type} Description
 */
function delegateEvents() {
  createDelegatedEventListener("click", SEL_PS_TAB_CONTROL_GLOBAL, "selectPageSelectTab");
}

export default function initialisePageSectionTabs() {
  // Create delegated event listeners for the components within this module
  delegateEvents();

  const PAGE_SECTION_TAB_COMPONENTS = document.querySelectorAll(SEL_PS_TAB_COMPONENT);

  PAGE_SECTION_TAB_COMPONENTS.forEach((element) => {
    const newSectionTabs = new PageSectionTabs(element);
  });
}


