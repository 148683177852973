"use strict";

// Custom Event Polyfill to fix issue in IE11
(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  window.CustomEvent = CustomEvent;
})();

import { ready, bindGlobalResizeMessage } from "@wearegood/good-utilities";

import ShowHideAPI from "Modules/Showhide";
// import ModalAPI from "Modules/Modal";
// import SmartImageAPI from "Modules/SmartImage";
// import InlineVideoAPI from "Modules/InlineVideo";
import NavigationAPI from "Modules/Navigation";
import TabsAPI from "Modules/Tabs";
import DateColourAPI from "Modules/DateColour";

// import CarouselAPI from "Modules/Carousel";
import LoaderAPI from "Modules/Loader";
// import GridAPI from "Modules/Grid";
import ProductSearchAPI from "Modules/ProductSearch/ProductSearch";
import MultiSectionArticleAPI from "Modules/MSA/MSA"; 
 
/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */ 
function initialiseComponentModules() {
  NavigationAPI.initialiseNavigation();
  NavigationAPI.initialisePageSectionTabs();

  DateColourAPI.initialiseDateColours();

  // SmartImageAPI.initSmartImages(); 
  // InlineVideoAPI.initInlineVideos();
  // ModalAPI.initialiseModals();
  MultiSectionArticleAPI.initMSA(); 

  TabsAPI.initialiseTabs();
  ShowHideAPI.initShowHideModule();
  // CarouselAPI.initialiseCarousels();
  
  // GridAPI.initGrids();
  ProductSearchAPI.initModule(); 

  bindGlobalResizeMessage();

  LoaderAPI.initLoader();

  // if(ComCheck.component) {
  //   ComCheck.component.initialiseComChecker();
  // }
}

ready(initialiseComponentModules);
