// Show/Hide Components module
"use strict";

/////////////
// Imports //
/////////////

import smoothscroll from 'smoothscroll-polyfill';
import "nodelist-foreach-polyfill";
import PubSub from "pubsub-js";
import { createCustomEvent, collapseElement, expandElement, messages as MESSAGES } from "@wearegood/good-utilities";
import * as CONSTANTS from "Modules/ShowHide/constants";

///////////////
// Constants //
///////////////

/////////////////////////
// Classes & Functions //
/////////////////////////

smoothscroll.polyfill();

function getElementOffsetOnPage(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}


/**
 * ShowHide - Class representing a Show/Hide DOM component
 */
export default class ShowHide {
  /**
   * constructor - Description
   *
   * @param {object} element DOM element
   *
   * @returns {type} Description
   */
  constructor(element) {
    // Set properties
    this.component = element;
    this.action = this.component.querySelectorAll(CONSTANTS.SEL_ACTION)[0];
    this.content = this.component.querySelectorAll(CONSTANTS.SEL_CONTENT)[0];
    this.config = JSON.parse(this.component.getAttribute("data-showhide-config"));
    this.animate = this.config.animate || false;
    this.startState = this.config.open || false;
    this.mode = this.config.mode || "css";
    this.expanded = false;
  }

  openComponent() { 
    if(this.mode === 'js') {
      expandElement(this.content);
    }

    this.component.classList.remove(CONSTANTS.CLASS_HIDE);
    this.component.classList.add(CONSTANTS.CLASS_DISPLAY);
    this.content.setAttribute('aria-hidden','false');
    this.expanded = true;

    let offset = getElementOffsetOnPage(this.component);

    window.scrollTo({ 
      top:(offset.top - 30),
      left:0,
      behavior:'smooth'  
    });
  }

  closeComponent() {
    if(this.mode === 'js') {
      collapseElement(this.content);
    }
    this.component.classList.remove(CONSTANTS.CLASS_DISPLAY);
    this.component.classList.add(CONSTANTS.CLASS_HIDE);
    this.content.setAttribute('aria-hidden','true');
    this.expanded = false;
  }

  /**
   * setStartState - Description
   *
   * @returns {type} Description
   */
  setStartState() {
    // Add inline CSS for transition time
    if(this.animate) {
      this.component.classList.add(CONSTANTS.CLASS_ANIMATE);
    }

    if (this.startState === true) {
      //expandElement(this.content);
      this.component.classList.add(CONSTANTS.CLASS_DISPLAY);
      this.content.setAttribute('aria-hidden','false');
      this.expanded = true;
    } else {
      this.component.classList.add(CONSTANTS.CLASS_HIDE);
      this.content.setAttribute('aria-hidden','true');
    }
  }

  /**
   * toggleControl - Description
   *
   * @param {type} event Description
   */
  toggleComponent(event) {
    if (this.expanded) {
      this.handleCloseEvent(event);
    } else {
      this.handleOpenEvent(event);
    }

    PubSub.publish(MESSAGES.contentChange);
  }

  handleOpenEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.openComponent();
  }

  handleCloseEvent(event) {
    event.preventDefault();
    event.stopPropagation();

    this.closeComponent();
  }

  /**
   * bindCustomMessageEvents - Description
   *
   * @returns {type} Description
   */
  bindCustomMessageEvents() {
    this.component.addEventListener(
      CONSTANTS.ACTION_EVENT,
      this.toggleComponent.bind(this)
    );
  }
}

