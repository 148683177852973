// Show/Hide Constants

// Shared Selectors
export const SEL_COMPONENT = "[data-showhide=component]";
export const CLASS_DISPLAY = "is_Open";
export const CLASS_HIDE = "is_Closed";
export const CLASS_ANIMATE ="is_Animated";

// Action Selectors
export const SEL_ACTION = "[data-showhide=component] [data-showhide=toggle]";
export const SEL_CONTENT = "[data-showhide=content]";
export const ACTION_EVENT = "toggleShowHide";

export const SEL_SECTION_TOGGLE = "[data-showhide=component] [data-showhide-section=toggle]";
export const SEL_SECTION_CONTENT = "[data-showhide-section=content]";
export const EVENT_SECTION_TOGGLE = "toggleSection";
export const EVENT_SECTION_OPEN = "openSection";
export const EVENT_SECTION_CLOSE = "closeSection";

export const SEL_SUBSECTION_TOGGLE = "[data-showhide=component] [data-showhide-subsection=toggle]";
export const SEL_SUBSECTION_CONTENT = "[data-showhide-subsection=content]";
export const EVENT_SUBSECTION_TOGGLE = "toggleSubsection";
export const EVENT_SUBSECTION_OPEN = "openSubsection";
export const EVENT_SUBSECTION_CLOSE = "closeSubsection";

export const MESSAGE_CLOSE_SECTIONS = "article/close main sections";
export const MESSAGE_CLOSE_SUBSECTIONS = "article/close subsections";


