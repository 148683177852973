// ShowHide Component Manager module
"use strict";

/////////////
// Imports //
/////////////

import "nodelist-foreach-polyfill";
import { createDelegatedEventListener } from "@wearegood/good-utilities";
import * as CONSTANTS from "Modules/ShowHide/constants";
import ShowHide from "Modules/ShowHide/ShowHide";
import SectionToggle from "Modules/ShowHide/SectionToggle";
import SubSectionToggle from "Modules/ShowHide/SubSectionToggle";

///////////////
// Constants //
///////////////

/////////////////////////
// Classes & Functions //
/////////////////////////

function initialiseShowHiders() {
  const COMPONENTS = document.querySelectorAll(CONSTANTS.SEL_COMPONENT);

  COMPONENTS.forEach(element => {
    const SHOWHIDE_TYPE = JSON.parse(element.dataset.showhideConfig).type;

    if (SHOWHIDE_TYPE === "section") {
      let sectionToggle = new SectionToggle(element);
    } else if (SHOWHIDE_TYPE === "subsection") {
      let subsectionToggle = new SubSectionToggle(element);
    } else {
      let basicShowHider = new ShowHide(element);
    }
  });
}

/**
 * Delegate global event listeners for carousel components
 *
 */
function delegateEvents() {
  createDelegatedEventListener("click", CONSTANTS.SEL_ACTION, CONSTANTS.ACTION_EVENT);
  createDelegatedEventListener("click", CONSTANTS.SEL_SECTION_TOGGLE, CONSTANTS.EVENT_SECTION_TOGGLE);
  createDelegatedEventListener("click", CONSTANTS.SEL_SUBSECTION_TOGGLE, CONSTANTS.EVENT_SUBSECTION_TOGGLE);
}

/**
 * initModule - Initialise this module and the components contained in it
 *
 * @returns {type} Description
 */
export default function initShowHideModule() {
  // Find and initialise Show/Hide components using the ShowHide class
  initialiseShowHiders();
  delegateEvents();
}
