"use strict";

////////////////////
// Module Imports //
//////////////////// 

import "nodelist-foreach-polyfill";

////////////////////// 
// Module Constants //
//////////////////////

const SEL_DATE_COLOUR = "[data-date-colour]";

const NEW_COLOUR_HUE = 120;
const OLD_COLOUR_HUE = 205;

const SATURATION = 100;
const LUMINANCE = 35;

const DAY_RANGE = 28;

////////////////////////////////
// Module Classes & Functions //
////////////////////////////////

class DateColour {
  constructor(element) {
    this.element = element;
    this.config = this.element.dataset.dateColourConfig || {};
    this.dateRange = this.config.dayRange || DAY_RANGE;
    this.colourSat = this.config.saturation || SATURATION;
    this.colourLum = this.config.luminance || LUMINANCE;
    this.date = Date.parse(this.element.dataset.dateColour);
    this.hueUnit = Math.abs(NEW_COLOUR_HUE - OLD_COLOUR_HUE) / DAY_RANGE;

    this.setColour();
  }

  setColour () { 
    const dateNow = Date.now();
    const TIME_DIFF = dateNow - this.date;
    const TIME_IN_DAYS = Math.floor(TIME_DIFF / (1000*60*60*24));

    let hueToSet = OLD_COLOUR_HUE;

    if (TIME_IN_DAYS > 0) {
      if(NEW_COLOUR_HUE > OLD_COLOUR_HUE) {
        // If the new hue colour is more than the old hue colour
        // we need to remove units from the new hue to move the colour in
        // the direction of the old hue
        hueToSet = NEW_COLOUR_HUE - (this.hueUnit * TIME_IN_DAYS);
      } else if (NEW_COLOUR_HUE < OLD_COLOUR_HUE) {
        // If the new hue colour is less than the old hue colour
        // we need to add units to the new hue to move the colour in
        // the direction of the old hue
        hueToSet = NEW_COLOUR_HUE + (this.hueUnit * TIME_IN_DAYS);
      }
    }

    const COLOUR_STRING  = `hsl(${Math.floor(hueToSet)},${this.colourSat}%,${this.colourLum}%)`;
    this.element.style.backgroundColor = COLOUR_STRING;
  }
}

export default function initialiseDateColours() {
  const DATA_COLOURS = document.querySelectorAll(SEL_DATE_COLOUR);

  DATA_COLOURS.forEach((element) => {
    const newDateColour = new DateColour(element);
  });
}